import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { getCurrentRichContent } from "../helpers/richContentHelpers";

const newsPageTplData = (id) => {
  const data = useStaticQuery(
    graphql`
      {
        allWpPage {
          edges {
            node {
              id
              template {
                ... on WpTemplate_News {
                    newsRichContent {
                    # SECTION: Page Header
                    pageHeader {
                      title
                      text
                    }

                    # SECTION: Posts List
                    postsList {
                      postsPerPage
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  );

  return getCurrentRichContent(data, id, "newsRichContent");
};

export { newsPageTplData };
